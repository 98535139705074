export default {
  name: 'ImageWithIconType',

  props: {
    src: {
      default: undefined,
      type: String,
    },

    icon: {
      type: Array,
      default: () => ['fal', 'camera-retro'],
    },
    aspect: {
      default: '4:4', // 4:4 square and 9:16 for stories
    },
  },

  data() {
    return {
      urlImage: null,
      observer: null,
      width: 0,
      isStory: '9:16',
    };
  },

  computed: {
    aspectRate() {
      if (this.width) {
        const aspect = this.aspect.split(':');

        const rate = aspect[0] / aspect[1];

        const width = `100%`;
        const height = `${this.width / rate}px`;

        return { width, height };
      }

      return {};
    },

    dinamicStyle() {
      return { backgroundImage: `url(${this.urlImage})`, ...this.aspectRate };
    },
  },

  methods: {
    generateUrlImage() {
      if (!this.urlImage) {
        this.urlImage = this.src
          ? this.src
          : this.baseUrl('img/placeholder_default.png');
      }
    },
  },

  mounted() {
    if (this.aspect === this.isStory) {
      this.width = this.$el.offsetWidth;
    }

    const options = {
      root: null,
      rootMargin: '10px',
      threshold: [0],
    };

    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.generateUrlImage();

          setTimeout(() => {
            this.observer.disconnect();
          }, 100);
        }
      });
    }, options);

    this.observer.observe(this.$refs.background);
  },
};
